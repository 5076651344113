.project__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.project__cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
}

.project__card {
  background: var(--color-bg-variant);
  border: 1px solid transparent;
  border-radius: 1rem;
  padding: 2rem;
  text-align: center;
  transition: var(--transition);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 2.5rem;
  width: 100%;  
}

.project__card:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.project__img__container {
  width: 10%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.project__data__container {
  width: 90%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;

}

.project__data {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.project__titles {
  width: 60%;
}

.project__duration {
  width: 40%;
  text-align: right;
}
